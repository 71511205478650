const filterCheckbox = function () {
  var tags = getParameterValue("tags");
  function getParameterValue(param) {
    var url = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");
    for (var i = 0; i < url.length; i++) {
      var urlparam = url[i].split("=");
      if (urlparam[0] == param) {
        return urlparam[1];
      }
    }
  }

  if (window.location.href.indexOf("tags=") > -1) {
    $(".evhc-filter-checkbox input").each(function () {
      var tagsArray = tags.split(',');
      var value = $(this).attr("value");
      $(this).attr("checked", tagsArray.indexOf(value) >= 0);
    });
    $("#pagination a").each(function () {
      var page = $(this).attr("href");
      $(this).attr("href", page + "&tags=" + tags);
    });
  } else {
    $(".evhc-filter-checkbox input").attr("checked", true);
  }

  $(".evhc-filter-checkbox input").on("change", function () {
    var base = window.location.href.split('?')[0];
    var values = $(".evhc-filter-checkbox input:checked").map(function () { return this.value })
      .get().join(',');
    if (values) {
      window.location = base + "?tags=" + values + "&pg=1";
    } else {
      window.location = base;
    }
  });
};

export default filterCheckbox;
