export function navbar() {
    window.addEventListener('scroll', function () {
        const navbarElement = $('.navbar');
        const searchFormElement = $('.search-form');

        if (searchFormElement && searchFormElement.length) {

            if ($(searchFormElement).hasClass('show')) {
                searchFormElement.removeClass('show');
            }

            if (window.scrollY > 50) {
                navbarElement.addClass('fixed-top');
            } else {
                navbarElement.removeClass('fixed-top');
            }
        }

    });

    $('.btn-search').on('click', function () {
        if (this.getAttribute('aria-expanded')) {
            $('#search').focus();
        }
    });
}
