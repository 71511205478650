export function sizeBannerPie(){
    $.each($('.banner-pie .container .row'), function () {
        var $image = $(this).find('.image');
        var $svg = $image.find('svg');
        var $description = $(this).find('.description');

        var $height = $description.height() + 100
        $height = $height < 604 ? $height : 604

        $image.css({ 'height': $height + 'px' });

        $image.css({ 'top': $description.height() + 190 - $height + 'px '})
    });

    $.each($('.banner-pie--right .container .row .image svg'), function () {
        const $width = $(window).width() - $(this).offset().left;
        $(this).css({ 'width': $width < 950 ? $width : 950 + 'px' });
    });
}
