import { navbar } from './components/navbar';
import { roundedImage } from './components/rounded-image';
import filterCheckbox from './components/filter-checkboxes';

import { sizeBannerPie } from './components/banner-pie';

$(function () {

    navbar();
    roundedImage();
    filterCheckbox();

    $('figure.person__image a').on('mouseenter', function (e) {
        $(this).parent().parent().find('.person__name a').addClass('mouseenter')
    });
    $('figure.person__image a').on('mouseleave', function (e) {
        $(this).parent().parent().find('.person__name a').removeClass('mouseenter')
    });

    setTimeout(() => {
        sizeBannerPie();
    }, "50")

    $(window).on('resize', sizeBannerPie);

    $('div[itemprop="mainEntity"] h2').on('click', function (e) {
        $(this).parent().toggleClass('open');
    })

});