export function roundedImage() {
    const trheColumnContainer = $('.evhc-three-column-layout');
    if (trheColumnContainer) {
        const imageContainer = Array.from(document.getElementsByClassName('evhc-rounded-image'));
        if (imageContainer.length) {
            const iWidth = imageContainer[0].clientWidth;
            imageContainer.forEach(element => {
                element.style.height = iWidth + "px";
            });
        }
    }
}